import InputMask from "inputmask";
import intlTelInput from "intl-tel-input";

export const im = InputMask("phone", {
  showMaskOnHover: true,
  showMaskOnFocus: true
});

export const iti = input =>
  intlTelInput(input, {
    allowDropdown: false,
    placeholderNumberType: "FIXED_LINE",
    utilsScript:
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.2/js/utils.js",
    formatOnDisplay: false,
    initialCountry: "auto",
  });

export const setMask = input => {
  iti(input);
  im.mask(input);
};

const inputPhones = document.querySelectorAll('input[type="tel"]');
const arr = [...inputPhones];

arr.forEach(input => setMask(input));
