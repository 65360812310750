function runCountdown(duration, update, complete) {
    let count = duration;
    const counter = setInterval(timer, 1000);

    function timer() {
        count = count - 1;
        if (count <= 0) {
            clearInterval(counter);
            complete();
            return;
        }

        update(count);
    }
}

function renderCounter(time, onStart, onComplete) {
    const wrapper = document.getElementById('sms_limit');
    const el = document.getElementById('sms_limit_time');

    function update(sec) {
        el.textContent = sec;
    }

    function complete() {
        wrapper.remove();
        localStorage.removeItem('sms_time');
        onComplete && onComplete();
    }

    onStart && onStart();
    wrapper.classList.remove('hidden');
    runCountdown(time, update, complete);
}

export function checkCounter(counterCallbacks) {
    const SMS_DELAY = 60;
    const smsTimeout = parseInt(localStorage.getItem('sms_time'), 10);
    const timeDiff = smsTimeout ? Math.round((Date.now() - smsTimeout) / 1000) : 0;
    const { onStart, onComplete } = counterCallbacks;

    if (timeDiff > 0 && timeDiff < SMS_DELAY) {
        renderCounter(SMS_DELAY - timeDiff, onStart, onComplete);
    }
    else {
        localStorage.removeItem('sms_time');
    }
}


